/* eslint-disable sonarjs/no-duplicate-string */
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Paper,
  PaperProps,
  Stack,
  Typography,
} from '@mui/material';
import { IconChevronDown, IconCircleX, IconFileCheck, IconFileSearch } from '@tabler/icons';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { projectAreas } from '../../../api/projects';
import { InputField } from '../../../components/InputField';
import { OverlayLoader } from '../../../components/OverlayLoader';
import { IOption } from '../../../components/Select';
import { ENVS, getEnv } from '../../../helpers/constants';
import { Gray, Interactive, Primary } from '../../../theme/tokens/colors'; // eslint-disable-line
import { ILegalProps } from './Legal.d';

const Legal: FC<ILegalProps> = ({ documentId, documents, canEdit }) => {
  const { control, setValue } = useFormContext();
  const mappedEnvsWithDocLabel = {
    [ENVS.DEV]: 'TEST DEV',
    [ENVS.STG]: 'TEST STG',
    [ENVS.PROD]: '',
  };
  const currentEnv = getEnv();
  const filteredDocuments = documents?.filter((document) => {
    if ((currentEnv as ENVS) !== ENVS.PROD) {
      return document.label.startsWith(mappedEnvsWithDocLabel[currentEnv]);
    } else {
      return (
        document.label.startsWith(mappedEnvsWithDocLabel[currentEnv]) &&
        Object.keys(mappedEnvsWithDocLabel)
          .filter((env) => env !== currentEnv)
          .every((env) => !document.label.startsWith(mappedEnvsWithDocLabel[env as ENVS]))
      );
    }
  });

  const CustomPaper = (props: PaperProps) => {
    return (
      <Paper
        sx={{
          '.MuiAutocomplete-option': { backgroundColor: `${Gray[50]} !important` },
          '.MuiAutocomplete-option.Mui-focused': { backgroundColor: `${Gray[200]} !important` },
          boxShadow:
            '0px 11px 15px -7px rgba(137, 140, 197, 0.2), 0px 16px 55px 8px rgba(137, 140, 197, 0.18), 0px 4px 24px -3px rgba(137, 140, 197, 0.15) !important',
        }}
        {...props}
      />
    );
  };

  const removeSelectedDocument = () => {
    setValue('legalInformation.document', '', {
      shouldDirty: true,
    });
  };

  return (
    <>
      <Grid container columns={{ lg: 8, xs: 4 }} columnSpacing={{ lg: 4 }} mb={4}>
        <Grid item xs={4}>
          <Controller
            control={control}
            name={'legalInformation.document'}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  renderInput={(params) => (
                    <InputField
                      label="Documents"
                      placeholder="Select a document"
                      {...params}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  options={filteredDocuments ?? []}
                  value={value ?? null}
                  getOptionLabel={(option) => {
                    return (
                      option.label ??
                      filteredDocuments?.find((item: IOption) => item.value === option)?.label ??
                      ''
                    );
                  }}
                  isOptionEqualToValue={(option, currValue) =>
                    option.value ?? option === currValue.value
                  }
                  onChange={(event: React.SyntheticEvent, newDocument: IOption | null) => {
                    onChange(event, newDocument);
                    setValue('legalInformation.document', newDocument?.value ?? '');
                  }}
                  popupIcon={<IconChevronDown />}
                  disableClearable
                  PaperComponent={CustomPaper}
                  disabled={!canEdit}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      {documentId && (
        <Box
          bgcolor={Primary[50]}
          height={56}
          px={2}
          sx={{ borderRadius: 2 }}
          alignItems="center"
          display="flex"
        >
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            display="flex"
            sx={{ width: '100%' }}
          >
            <IconFileCheck color={Primary[500]} />
            <Box>
              <Typography fontSize={14} sx={{ color: 'primary.secondary' }}>
                {filteredDocuments?.find((item: IOption) => item.value === documentId)?.label}
              </Typography>
            </Box>
            {canEdit && (
              <Box
                sx={{
                  position: 'absolute',
                  right: { lg: '86px', md: '36px', xs: '28px' },
                  top: 'auto',
                }}
              >
                <IconButton onClick={removeSelectedDocument} aria-label="delete">
                  <IconCircleX color={Interactive.secondary} size={16} />
                </IconButton>
              </Box>
            )}
          </Stack>
        </Box>
      )}
      {!documentId && canEdit && (
        <Stack
          direction="column"
          spacing={2}
          alignItems="center"
          display="flex"
          sx={{ color: 'text.secondary', width: '100%' }}
        >
          <IconFileSearch size={120} />
          <Typography variant="h2" align="center" sx={{ color: 'text.secondary' }}>
            You haven’t selected a document for this project
          </Typography>
        </Stack>
      )}
      <OverlayLoader area={projectAreas.getProjectLegalDocuments} theme="container" />
    </>
  );
};

export { Legal };
